import {useContext, useEffect} from 'react';
import moment from 'moment';
import {fastapi} from 'api';
import {useB2V, useMarel, useUser} from 'hooks';
import createPersistedState from 'use-persisted-state';
import {ProjectionsContext} from 'store/Projections';
import {accounts, json, objectEquals} from 'utils';

const useAgeIndex = createPersistedState('EP_ageIndex');

let memoized_user_id = '';
let memoized_marel_result = '';
let memoized_b2v = '';
let memoized_projection = '';
let memoized_age_index = 0;
let memoized_projections = '';

const getProjections = (user_id, callback) => {
    if (memoized_user_id === user_id) return;
    memoized_user_id = user_id;

    fastapi.post('/simulators/projections/initialize', {
        user_id: user_id
    }).then((response) => response.data && callback(response));
};

const saveProjections = (user_id, projections) => {
    if (memoized_projections === projections) return;
    memoized_projections = projections;

    fastapi.post('/simulators/projections/save', {
        user_id: user_id,
        projections: json.parse(projections)
    });
};

const computeRetirementProjection = (
    user,
    marel_result,
    B2V,
    projection,
    total_amount_collective,
    total_amount_individual,
    periodic_payments,
    ageIndex,
    callback
) => {
    if (!projection) return;

    const inputs = json.parse(projection).inputs;
    if (!inputs) return;
    if (Object.keys(inputs).length === 0) return;

    if (
        marel_result === memoized_marel_result &&
        projection === memoized_projection &&
        ageIndex === memoized_age_index &&
        B2V === memoized_b2v
    )
        return;
    memoized_marel_result = marel_result;
    memoized_projection = projection;
    memoized_age_index = ageIndex;
    memoized_b2v = B2V;

    fastapi.post('/simulators/projections/retirement', {
        ...inputs,
        marel_result: marel_result ? json.parse(marel_result) : null,
        b2v: B2V ? json.parse(B2V) : null,
        age_index: ageIndex || 0,
        allocated_accounts: user.origin === 'collective' ? json.parse(projection).allocated : {},
        total_amount_collective: user.origin === 'individual' ? total_amount_collective : 0,
        total_amount_individual: user.origin === 'individual' ? total_amount_individual : 0,
        periodic_payments: periodic_payments
    })
        .then((response) => callback(response))
};

const computeLifeProjection = (inputs, allocated) => {
    const diff_month = moment(inputs.horizon).diff(moment(new Date()), 'months', true);

    function reducer(periodicity) {
        switch (periodicity) {
            case 'one_shot':
                return parseInt(inputs.allocated_amount);
            case 'yearly':
                return (parseInt(inputs.allocated_amount) * diff_month) / 12;
            default:
                return parseInt(inputs.allocated_amount) * diff_month;
        }
    }

    function delta_reducer(periodicity) {
        switch (periodicity) {
            case 'one_shot':
                return parseInt(inputs.allocated_amount) / diff_month;
            case 'yearly':
                return parseInt(inputs.allocated_amount) / 12;
            default:
                return parseInt(inputs.allocated_amount);
        }
    }

    const allocation = reducer(inputs.allocated_periodicity) || 0;
    const total_allocated =
        allocation + (allocated ? Object.values(allocated).reduce((acc, val) => acc + parseInt(val), 0) : 0);

    const amount =
        parseInt(inputs.other_income) + parseInt(inputs.property_loan ? inputs.property_loan : 0) + total_allocated;

    const delta = parseInt(inputs.goal, 10) - amount;
    const delta_monthly = delta / diff_month + delta_reducer(inputs.allocated_periodicity) + 1;

    return {
        amount: amount,
        delta_monthly: Math.max(delta_monthly, 0)
    };
};

function useProjection(type) {
    const {user} = useUser();
    const {marel} = useMarel();
    const {B2V} = useB2V();
    const [ageIndex] = useAgeIndex(0);

    const {projections, setProjections} = useContext(ProjectionsContext);

    const setProjection = (value) => {
        setProjections({
            ...projections,
            [type]: value
        });
    };

    const inputs = JSON.stringify(projections[type]?.inputs);
    const allocated = JSON.stringify(projections[type]?.allocated);

    // Initial value in db : `user.projections`
    useEffect(() => {
        user.id &&
            Object.keys(projections).length === 0 &&
            getProjections(user.id, (response) => setProjections(response.data));

        // eslint-disable-next-line
    }, [user.id]);

    // Save value in db : `user.projections`
    useEffect(() => {
        user.id && Object.keys(projections).length !== 0 && saveProjections(user.id, JSON.stringify(projections));

        // eslint-disable-next-line
    }, [user.id, inputs, allocated]);

    // Compute projection `retirement`
    useEffect(() => {
        type === 'retirement' &&
            computeRetirementProjection(
                user,
                JSON.stringify(marel.result),
                JSON.stringify(B2V),
                JSON.stringify(projections.retirement),
                accounts
                    .filter(user.accounts, null, ['A83', 'PER', 'PERECO', 'PERCO', 'PERU'])
                    .map((account) => account.balance)
                    .reduce((acc, val) => acc + val, 0),
                accounts
                    .filter(user.accounts, null, ['RETIREMENT_SAVINGS'])
                    .map((account) => account.balance)
                    .reduce((acc, val) => acc + val, 0),
                accounts
                    .filter(user.accounts, null, ['RETIREMENT_SAVINGS'])
                    .filter((account) => account.periodic_payment)
                    .map((account) => account.periodic_payment),
                JSON.stringify(ageIndex),
                (response) => {
                    !objectEquals(response.data, projections.retirement.response) &&
                        setProjection({
                            ...projections.retirement,
                            response: response.data
                        });
                }
            );

        // eslint-disable-next-line
    }, [user.accounts, marel.result, inputs, allocated, ageIndex, B2V]);

    // Compute projection `owner`, `children`, `travel`...
    useEffect(() => {
        if (type !== 'retirement' && inputs && allocated && Object.keys(json.parse(inputs)).length !== 0) {
            setProjection({
                ...projections[type],
                response: computeLifeProjection(json.parse(inputs), json.parse(allocated))
            });
        }

        // eslint-disable-next-line
    }, [user.id, inputs, allocated]);

    return {
        projection: projections[type] || {},
        setProjection: setProjection
    };
}

export default useProjection;
