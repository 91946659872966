import React from 'react';
import PropTypes from 'prop-types';
import {Buttons, Modal, Typography} from 'components';
import {modal} from 'utils';
import uuid from 'uuid';
import {makeStyles} from '@material-ui/styles';
import {Box, useMediaQuery, useTheme} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 550,
        maxWidth: 550,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: '100%'
        }
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: '100%'
        }
    }
}));

export const getSsoUrl = (scope, endpoint) => {
    if (scope === 'ais') {
        const client_id = process.env.REACT_APP_AIS_SSO_CLIENT_ID || 'Unknown';
        const redirect_uri = process.env.REACT_APP_UI_URL || 'Unknown';
        const state = uuid();
        return `${process.env.REACT_APP_AIS_SSO_URL}?client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}`;
    } else if (scope === 's2e') {
        return `${process.env.REACT_APP_S2E_SSO_URL}${endpoint}`;
    } else return '';
};

export const ModalSso = ({hash, scope, title, message, titleVariant, endpoint=''}) => {
    const classes = useStyles();
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Modal
            className={classes.root}
            hash={hash}
            title={title ? title : 'Accès à cette opération'}
            titleVariant={titleVariant}
        >
            {message ? (
                message
            ) : (
                <Typography>
                    <>
                        Vous allez être redirigé vers votre espace transactionnel{' '}
                        <Typography
                            component="span"
                            semibold
                        >
                            {scope === 'ais' && 'Épargne Retraite'}
                            {scope === 's2e' && 'Épargne Salariale'}
                        </Typography>{' '}
                        afin de réaliser votre opération.
                        <br />
                        Laissez-vous guider !
                    </>
                </Typography>
            )}
            <Box
                display="flex"
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems="center"
                justifyContent="space-between"
            >
                <Buttons.Default
                    className={classes.button}
                    label={'buttons.cancel'}
                    color="white_border_blue"
                    centerText
                    role="cancel"
                    onClick={() => modal.close(hash)}
                />
                <Buttons.Default
                    className={classes.button}
                    label={'buttons.goOn'}
                    color="blue"
                    centerText
                    role="link"
                    onClick={() => window.open(getSsoUrl(scope, endpoint), '_blank')}
                />
            </Box>
        </Modal>
    );
};

function SSO(props) {
    const {hash, scope, label,endpoint, ...rest} = props;

    return (
        <>
            <Buttons.Default
                label={label}
                aria-haspopup
                color="orange"
                {...rest}
                onClick={() => modal.open(hash)}
            />
            <ModalSso
                scope={scope}
                hash={hash}
                endpoint={endpoint}
            />
        </>
    );
}

SSO.propTypes = {
    hash: PropTypes.string.isRequired,
    scope: PropTypes.oneOf(['ais', 's2e']).isRequired,
    label: PropTypes.string
};

export default SSO;
