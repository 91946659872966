import {Inputs} from '../../components';
import React from 'react';
import {useUser} from 'hooks';
import {useTranslation} from 'react-i18next';
import {sendTag} from 'store/Tags';
import {Language} from '@material-ui/icons';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingRight: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    select: {
        width: 65,
        margin: '0px',
        fontSize: '16px',
        fontWeight: 600,
        background: 'inherit',
        color: `${theme.palette.blue} !important`,
        backgroundSize: 24,
        padding: theme.spacing(0, 3, 0, 0.5),
        backgroundPosition: 'center right 8px',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${process.env.PUBLIC_URL}/icons/keyboard_arrow_down.svg)`,
        '&:focus': {
            backgroundImage: `url(${process.env.PUBLIC_URL}/icons/keyboard_arrow_up.svg)`
        },
        cursor: 'pointer'
    },
    icon: {
        color: theme.palette.blue,
        fontSize: '24px'
    }
}));

const LangSelect = () => {
    const {user, setUser} = useUser();

    const {i18n, t} = useTranslation();

    const classes = useStyles();

    const handleChangeLang = (lang) => {
        setUser({...user, ...{localization: lang}});
        i18n.changeLanguage(lang);
        const htmlRoot = document.getElementById('htmlRoot');
        if (htmlRoot) {
            htmlRoot.lang = lang;
        }
        sendTag(user, `cta_header::clic::${lang}`, {}, true);
    };

    const lang = user.localization || 'fr';

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        return null;
    }

    return (
        <div
            className={classes.root}
            aria-label={t('navigation.select')}
            title={t('navigation.select')}
        >
            <Language
                className={classes.icon}
                aria-hidden={true}
            />
            <Inputs.Select
                className={classes.select}
                value={lang}
                onInput={(event) => handleChangeLang(event.target.value)}
                options={[
                    {value: 'fr', label: 'FR'},
                    {value: 'en', label: 'EN'}
                ]}
            />
        </div>
    );
};

export default LangSelect;
