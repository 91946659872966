import {PERFORMANCE_DOCUMENT_URL} from './urlMapping';

const getPerformanceDocumentUrl = (user) => {
    if (user.scopes && user.scopes?.includes('s2e')) {
        if (user.s2e_profile?.companies?.filter((company) => ['31902', '31936'].includes(company.id)).length > 0) {
            return PERFORMANCE_DOCUMENT_URL.COCA;
        } else if (user.s2e_profile?.companies?.filter((company) => ['35196'].includes(company.id)).length > 0) {
            return PERFORMANCE_DOCUMENT_URL.DISNEY;
        } else {
            return null;
        }
    }

    if (user.scopes && user.scopes?.includes('ais')) {
        if (
            user.ais_profile?.ais_account_details?.filter((company) =>
                ['2726002000000', '2726002000300', '2726003010000', '2726003010100'].includes(company.enterprise_id)
            ).length > 0
        ) {
            return PERFORMANCE_DOCUMENT_URL.COCA;
        } else if (
            user.ais_profile?.ais_account_details?.filter((company) =>
                ['2722500061500', '2722500061400'].includes(company.enterprise_id)
            ).length > 0
        ) {
            return PERFORMANCE_DOCUMENT_URL.DISNEY;
        } else {
            return null;
        }
    }
};

export default getPerformanceDocumentUrl;
