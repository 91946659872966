import React, {useReducer} from 'react';
import moment from 'moment';
import clsx from 'clsx';
import {withRouter} from 'react-router-dom';
import {Buttons, Grid, Structure, Typography} from 'components';
import {TabEconomy, TabProposal} from './components';
import {StepAge, StepChildren, StepGender, StepMaritalStatus, StepMonthlyIncome} from '../../components';
import {useLoading, useTaxSimulator, useUser} from 'hooks';

import {Box, useMediaQuery} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/styles';
import {amount} from 'utils';
import {api} from 'api';

const useStyles = makeStyles((theme) => ({
    container: {
        margin: theme.spacing(2, 0),
        paddingTop: theme.spacing(2),
        justifyContent: 'center'
    },
    wrapper: {
        padding: 5,
        '&:first-child': {
            paddingLeft: 0
        },
        '&:last-child': {
            paddingRight: 0
        }
    },
    border: {
        height: '100%',
        minHeight: 150,
        border: `solid 2px ${theme.palette.grayLight}`,
        padding: theme.spacing(2, 1)
    },
    borderH: {
        borderTop: `solid 1px ${theme.palette.gray}`,
        borderBottom: `solid 1px ${theme.palette.gray}`,
        [theme.breakpoints.down('sm')]: {
            borderBottom: 'none'
        }
    },
    borderV: {
        borderLeft: `solid 1px ${theme.palette.gray}`
    },
    button: {
        margin: theme.spacing(1)
    },
    padding: {
        padding: theme.spacing(1, 0)
    },
    paddingBottom: {
        paddingBottom: theme.spacing(2)
    },
    paddingTop: {
        paddingTop: theme.spacing(2)
    },
    amount: {
        fontWeight: 600,
        color: theme.palette.blue,
        fontSize: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: 18
        }
    },
    background: {
        backgroundColor: theme.palette.pale
    }
}));

function reducer(state, periodicity) {
    switch (periodicity) {
        case 'one_shot':
            return '€';
        default:
            return '€/mois';
    }
}

function StepTaxResult({history, setStep}) {
    const {user} = useUser();
    const {taxSimulator, setTaxSimulator, variant} = useTaxSimulator();
    const {setLoading} = useLoading();

    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const [adornment, setAdornment] = useReducer(reducer, '€');

    return (
        <>
            {variant !== 'contained' && (
                <Box pb={0.5}>
                    <Typography
                        variant="h2"
                        size={isDesktop ? 32 : 24}
                        color="blue"
                        semibold
                        center
                    >
                        Résultat de votre simulation
                    </Typography>
                </Box>
            )}

            <Box my={2}>
                <Typography
                    center
                    size={isDesktop ? 20 : 18}
                >
                    Compte tenu de votre situation, <br />
                    nous vous suggérons de verser{' '}
                    <span className={classes.amount}>
                        {amount.format(taxSimulator.response.vv_envelope_monthly * 12, 0, "€ sur l'année")}.
                    </span>
                </Typography>
            </Box>

            <Grid
                className={classes.container}
                container
            >
                <Grid
                    className={clsx({
                        [classes.wrapper]: variant === 'contained' && isDesktop,
                        [classes.padding]: true,
                        [classes.paddingBottom]: variant !== 'contained' && !isDesktop,
                        [classes.background]: variant !== 'contained'
                    })}
                    item
                    md={6}
                    xs={12}
                >
                    {variant === 'contained' ? (
                        <Structure.Carousel
                            adaptiveHeight
                            className={classes.border}
                            initialSlide={user.ais_profile?.gender && user.ais_profile.birth_date && 2}
                            smooth
                        >
                            <StepGender />
                            <StepAge />
                            <StepMaritalStatus />
                            <StepChildren />
                            <StepMonthlyIncome />
                            <TabProposal
                                adornment={adornment}
                                setAdornment={setAdornment}
                            />
                        </Structure.Carousel>
                    ) : (
                        <TabProposal
                            adornment={adornment}
                            setAdornment={setAdornment}
                            variant={variant}
                        />
                    )}
                </Grid>
                <Grid
                    className={clsx({
                        [classes.wrapper]: variant === 'contained' && isDesktop,
                        [classes.padding]: true,
                        [classes.paddingTop]: variant !== 'contained' && !isDesktop
                    })}
                    item
                    md={6}
                    xs={12}
                >
                    <TabEconomy />
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        direction={isDesktop ? 'row' : 'column'}
                    >
                        <Buttons.Default
                            label="Refaire une simulation"
                            color="white_border_blue"
                            centerText
                            onClick={() => {
                                setTaxSimulator({
                                    inputs: {
                                        gender: user.ais_profile?.gender,
                                        client_age:
                                            user.ais_profile?.birth_date &&
                                            moment().diff(user.ais_profile?.birth_date, 'years', false),
                                        allocated_amount: undefined
                                    },
                                    response: {}
                                });
                                setStep(user.ais_profile?.gender && user.ais_profile?.birth_date ? 2 : 0);
                            }}
                        />

                        {variant === 'public' && (
                            <Buttons.Default
                                label="Verser sur mon compte"
                                className={classes.button}
                                centerText
                                color="blue"
                                onClick={() => {
                                    setLoading(true);
                                    localStorage.clear();

                                    return api
                                        .get('/login/oauth/authorization/collective')
                                        .then((response) => {
                                            window.location.href = response.data;
                                        })
                                        .finally(() => setLoading(false));
                                }}
                            />
                        )}
                        {variant === 'standalone' && (
                            <Buttons.Default
                                label="Faire un versement"
                                className={classes.button}
                                color="blue"
                                centerText
                                onClick={() => history.push('/transactional/payment')}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default withRouter(StepTaxResult);
