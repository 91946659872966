import React, {useEffect, useMemo, useState} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {Buttons, Charts, Grid, Icon, Info, Loaders, Modal, Typography} from 'components';
import {amount, modal, wording} from 'utils';
import {fetchS2EInvestmentFundURL, fetchS2EInvestmentValuations} from 'api/calls';

import {Box, Button, ButtonGroup, Divider, Paper, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import {sendTag} from 'store/Tags';
import {useUser} from 'hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 700,
        maxHeight: '80%'
    },
    container: {
        padding: theme.spacing(0.5, 0)
    },
    paper: {
        padding: theme.spacing(1.5, 1),
        borderRadius: '5px',
        boxShadow: '0px 0px 5px #00000033'
    },
    icon: {
        display: 'block',
        margin: theme.spacing(0, 0, 0.5)
    },
    button: {
        border: 'none !important',
        fontWeight: 'bold',
        background: theme.palette.white,
        color: theme.palette.blue,
        textTransform: 'inherit'
    },
    selected: {
        color: `${theme.palette.white} !important`,
        backgroundColor: `${theme.palette.blue} !important`
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: 450,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 260
        }
    },
    riskLevel: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(0, 2, 0, 0)
    },
    bar: {
        width: 26,
        height: 3,
        marginRight: 5,
        background: theme.palette.grayLight
    },
    performance: {
        borderWidth: 2,
        fontSize: 16
    }
}));

const RiskLevel = ({level}) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
            >
                <Box mr={0.2}>
                    <Typography
                        variant="h3"
                        color="turquoiseDark"
                        bold
                    >
                        {level}
                    </Typography>
                </Box>
                <Typography semibold>/7</Typography>
            </Box>

            <div className={classes.wrapper}>
                {Array.from(Array(level).keys()).map((index) => (
                    <div
                        className={classes.bar}
                        key={`bar-colored-${index}`}
                        style={{background: theme.palette.turquoiseDark}}
                    />
                ))}

                {Array.from(Array(7 - level).keys()).map((index) => (
                    <div
                        className={classes.bar}
                        key={`bar-${index}`}
                    />
                ))}
            </div>
        </>
    );
};

const DEFAULT_FUND_URL = 'https://www.axa-im.fr';

function FundModal({hash, investment, policy_id, ...rest}) {
    const classes = useStyles();
    const open = modal.isOpen(hash);
    const {user} = useUser();

    const [valuations, setValuations] = useState(null);
    useEffect(() => {
        if (open && valuations === null)
            fetchS2EInvestmentValuations(policy_id, investment.id)
                .then((data) => {
                    sendTag(user, `performance_${investment.title}`);
                    setValuations(data.s2e_valuations.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1)));
                })
                .catch((error) => {
                    sendTag(user, `performance_${investment.title}_ko`);
                    setValuations([]);
                });

        // eslint-disable-next-line
    }, [open, policy_id, investment.id]);

    const [fundURL, setFundURL] = useState(null);
    useEffect(() => {
        if (open && fundURL === null)
            fetchS2EInvestmentFundURL(policy_id, investment.id)
                .then((data) => setFundURL(data.s2e_fund_url || DEFAULT_FUND_URL))
                .catch((error) => setFundURL(DEFAULT_FUND_URL));

        // eslint-disable-next-line
    }, [open, policy_id, investment.id]);

    const [period, setPeriod] = useState(null);

    const config = useMemo(() => {
        if (valuations instanceof Array)
            return [
                ...(valuations.find((valuation) => moment(valuation.date).isAfter(moment().utc().startOf('year')))
                    ? [
                          {
                              name: 'current_year',
                              label: "Début d'année",
                              moment: moment().utc().startOf('year')
                          }
                      ]
                    : []),
                ...(valuations.find((valuation) => moment(valuation.date).isAfter(moment.utc().subtract(1, 'year')))
                    ? [
                          {
                              name: 'last_year',
                              label: '1 an',
                              moment: moment.utc().subtract(1, 'year')
                          }
                      ]
                    : []),
                ...(valuations.find((valuation) =>
                    moment(valuation.date).isBetween(moment.utc().subtract(3, 'year'), moment.utc().subtract(1, 'year'))
                )
                    ? [
                          {
                              name: 'last_three_years',
                              label: '3 ans',
                              moment: moment.utc().subtract(3, 'year')
                          }
                      ]
                    : []),
                ...(valuations.find((valuation) =>
                    moment(valuation.date).isBetween(moment.utc().subtract(5, 'year'), moment.utc().subtract(3, 'year'))
                )
                    ? [
                          {
                              name: 'last_five_years',
                              label: '5 ans',
                              moment: moment.utc().subtract(5, 'year')
                          }
                      ]
                    : [])
            ];
    }, [valuations]);

    const valuationsDisplayed = useMemo(() => {
        if (valuations instanceof Array && config instanceof Array && period !== null) {
            return valuations.filter((valuation) =>
                moment(valuation.date).isAfter(config.find((current) => current.name === period).moment)
            );
        }
        return null;
    }, [valuations, period, config]);

    useEffect(() => {
        if (config instanceof Array && config.length > 0) setPeriod(config[0].name);
    }, [config]);

    if (!investment) return null;

    const oneYearAgoValuation =
        valuations instanceof Array
            ? []
                  .concat(valuations)
                  .reverse()
                  .find((valuation) => moment(valuation.date).isBefore(moment.utc().subtract(1, 'years')))
            : null;
    const threeYearsAgoValuation =
        valuations instanceof Array
            ? []
                  .concat(valuations)
                  .reverse()
                  .find((valuation) => moment(valuation.date).isBefore(moment.utc().subtract(3, 'years')))
            : null;
    const fiveYearsAgoValuation =
        valuations instanceof Array
            ? []
                  .concat(valuations)
                  .reverse()
                  .find((valuation) => moment(valuation.date).isBefore(moment.utc().subtract(5, 'years')))
            : null;

    return (
        <Modal
            hash={hash}
            className={classes.root}
            title={investment.label}
            {...rest}
        >
            <Typography
                variant="h5"
                color="grayLight2"
                center
            >
                Fiche fonds
            </Typography>

            <Box height={30} />

            <Typography
                variant="h4"
                color="blue"
                semibold
            >
                Société de gestion
            </Typography>

            <Typography variant="h5">{investment.management_company}</Typography>

            <Box my={2}>
                <Divider />
            </Box>

            <Typography
                variant="h4"
                color="blue"
                semibold
            >
                Indicateurs
            </Typography>

            <Grid
                container
                spacing={2}
                className={classes.container}
            >
                <Grid
                    item
                    sm={6}
                    xs={12}
                >
                    <Paper className={classes.paper}>
                        <Icon
                            name="axa-icons-newspepper"
                            className={classes.icon}
                            color="blue"
                            size={25}
                        />
                        <Typography
                            variant="h5"
                            color="dark"
                            semibold
                        >
                            Classification AMF
                        </Typography>
                        <Typography
                            variant="h6"
                            color="blue"
                            semibold
                            gutterBottom={false}
                        >
                            <br />
                            {investment.classification}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid
                    item
                    sm={6}
                    xs={12}
                >
                    <Paper className={classes.paper}>
                        <Icon
                            name="axa-icons-danger"
                            className={classes.icon}
                            color="blue"
                            size={25}
                        />
                        <Typography
                            variant="h5"
                            color="dark"
                            semibold
                        >
                            Échelle de risque{' '}
                            <Info
                                title="Niveau de risque"
                                tooltip
                                color="blue"
                                placement="top"
                                variant="outlined"
                            >
                                <div>
                                    <strong>1</strong> : risque très faible
                                    <br />
                                    <strong>7</strong> : risque très élevé
                                </div>
                            </Info>
                        </Typography>

                        <RiskLevel level={investment.risk_level} />
                    </Paper>
                </Grid>
                <Grid
                    item
                    sm={6}
                    xs={12}
                >
                    <Paper className={classes.paper}>
                        <Icon
                            name="axa-icons-clock"
                            className={classes.icon}
                            color="blue"
                            size={25}
                        />
                        <Typography
                            variant="h5"
                            color="dark"
                            semibold
                        >
                            Durée minimum de placement conseillée
                        </Typography>
                        <Typography
                            variant="h6"
                            color="blue"
                            semibold
                            gutterBottom={false}
                        >
                            {wording.translateFundHorizon(investment.horizon)}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            {((valuations instanceof Array && valuations.length > 0) || valuations === null) && (
                <>
                    <Box my={2}>
                        <Divider />
                    </Box>
                    <Typography
                        variant="h4"
                        color="blue"
                        semibold
                    >
                        Valeur de la part
                    </Typography>
                </>
            )}

            {valuations instanceof Array && valuations.length > 0 && period !== null ? (
                <>
                    <Grid
                        container
                        spacing={2}
                        className={classes.container}
                    >
                        <Grid
                            item
                            sm={7}
                            xs={12}
                        >
                            <Paper className={classes.paper}>
                                <Icon
                                    name="axa-icons-calendar"
                                    className={classes.icon}
                                    color="blue"
                                    size={25}
                                />

                                <Typography
                                    variant="h6"
                                    semibold
                                >
                                    Périodicité : {investment.valuation_frequency}
                                </Typography>

                                <Box my={1.5}>
                                    <Divider />
                                </Box>

                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="h6">
                                        Au {moment(valuations[valuations.length - 1].date).format('DD/MM/YYYY')}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        color="blue"
                                        semibold
                                    >
                                        {amount.format(valuations[valuations.length - 1].amount, 2, 'EUR')}
                                    </Typography>
                                </Box>

                                {oneYearAgoValuation && (
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Typography variant="h6">
                                            Il y a 1 an ({moment(oneYearAgoValuation.date).format('DD/MM/YYYY')})
                                        </Typography>
                                        <Typography variant="h6">
                                            {amount.format(oneYearAgoValuation.amount, 2, 'EUR')}
                                        </Typography>
                                    </Box>
                                )}

                                {threeYearsAgoValuation && (
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Typography variant="h6">
                                            Il y a 3 ans ({moment(threeYearsAgoValuation.date).format('DD/MM/YYYY')})
                                        </Typography>
                                        <Typography variant="h6">
                                            {amount.format(threeYearsAgoValuation.amount, 2, 'EUR')}
                                        </Typography>
                                    </Box>
                                )}

                                {fiveYearsAgoValuation && (
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Typography variant="h6">
                                            Il y a 5 ans ({moment(fiveYearsAgoValuation.date).format('DD/MM/YYYY')})
                                        </Typography>
                                        <Typography variant="h6">
                                            {amount.format(fiveYearsAgoValuation.amount, 2, 'EUR')}
                                        </Typography>
                                    </Box>
                                )}

                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="h6">
                                        À la création ({moment(valuations[0].date).format('DD/MM/YYYY')})
                                    </Typography>
                                    <Typography variant="h6">
                                        {amount.format(valuations[0].amount, 2, 'EUR')}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Box my={2}>
                        <Divider />
                    </Box>

                    <Typography
                        variant="h4"
                        color="blue"
                        semibold
                    >
                        Évolution des valeurs liquidatives en France
                    </Typography>

                    <Box
                        display="flex"
                        alignItems="flex-end"
                        mb={1}
                    >
                        <Box mr={0.5}>
                            <Typography
                                variant="h4"
                                semibold
                                gutterBottom={false}
                            >
                                {amount.format(valuations[valuations.length - 1].amount, 2, 'EUR')}
                            </Typography>
                        </Box>
                        <Typography
                            variant="h5"
                            gutterBottom={false}
                        >
                            au {moment(valuations[valuations.length - 1].date).format('DD/MM/YYYY')}
                        </Typography>
                    </Box>

                    <Box py={1.5}>
                        <ButtonGroup
                            color="primary"
                            variant="text"
                        >
                            <Box
                                boxShadow="0px 0px 5px #00000033"
                                borderRadius="5px"
                            >
                                {config.map((current) => {
                                    return (
                                        <Button
                                            key={`button-${current.name}`}
                                            className={clsx(
                                                classes.button,
                                                period === current.name && classes.selected
                                            )}
                                            onClick={() => setPeriod(current.name)}
                                        >
                                            {current.label}
                                        </Button>
                                    );
                                })}
                            </Box>
                        </ButtonGroup>
                    </Box>

                    {valuationsDisplayed && (
                        <Charts.Area
                            data={valuationsDisplayed.map((valuation) => valuation.amount)}
                            labels={valuationsDisplayed.map((valuation) => new Date(valuation.date))}
                            height={450}
                        />
                    )}
                </>
            ) : (
                valuations === null && (
                    <Box
                        position="relative"
                        height={200}
                    >
                        <Loaders.Circular
                            open
                            blue
                            transparent
                        />
                    </Box>
                )
            )}

            <Box my={2}>
                <Divider />
            </Box>

            <Typography
                variant="h4"
                color="blue"
                semibold
            >
                Seuil de déclenchement
            </Typography>

            <Typography variant="h5">
                Vous pouvez préciser sur certaines opérations un montant à partir duquel votre ordre va se déclencher.
                Si le cours de clôture de l'action souhaité est atteint, l'opération est exécutée.
            </Typography>

            <Box my={2}>
                <Divider />
            </Box>

            <Typography
                variant="h4"
                color="blue"
                semibold
            >
                Performances
            </Typography>

            <Typography variant="h5">
                Vous pouvez consulter les performances et prendre connaissance de toute la documentation sur ce support
                de placement (Règlements, prospectus, documents d'informations clés pour l'investisseur (DIC)).
            </Typography>

            <Box pt={2}>
                <Buttons.Default
                    className={classes.performance}
                    label="Performance du fonds"
                    color="blueAlpha"
                    center
                    next
                    onClick={() => window.open(fundURL, '_blank')}
                />
            </Box>
        </Modal>
    );
}

FundModal.propTypes = {
    hash: PropTypes.string.isRequired,
    policy_id: PropTypes.string.isRequired,
    investment: PropTypes.object.isRequired
};

export default FundModal;
