import React, {useState} from 'react';
import {fastapi} from 'api';
import {Alert, Buttons, Form, Inputs, Typography} from 'components';
import {useMarel, useUser} from 'hooks';
import {sendTag} from 'store/Tags';

import {makeStyles} from '@material-ui/styles';
import {encryptCredential} from "../../StepLogin";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: theme.spacing(2, 2, 1)
        },
        padding: theme.spacing(3, 4, 2),
        background: theme.palette.white
    }
}));

const LoginForm = ({setCredentials, user, setSubStep, publicKey, setSessionId}) => {
    return <Form
        schema={{
            identifier: {
                presence: {
                    allowEmpty: false,
                    message: 'Veuillez renseigner votre numéro de sécurité sociale'
                },
                format: {
                    pattern: '[12][0-9]{2}[0-1][0-9](2[AB]|[0-9]{2})[0-9]{3}[0-9]{3}',
                    message: 'Votre numéro de sécurité sociale est invalide'
                }
            },
            password: {
                presence: {
                    allowEmpty: false,
                    message: 'Veuillez renseigner votre mot de passe ameli.fr'
                }
            }
        }}
        submit={(formState, setFormState) =>
            fastapi
                .post('/simulators/marel/login/ameli', {
                    identifier: encryptCredential(formState.values.identifier, publicKey),
                    password: encryptCredential(formState.values.password, publicKey)
                })
                .then((response) => {
                    setCredentials({
                        identifier: formState.values.identifier,
                        password: formState.values.password
                    });
                    setSessionId(response.data.session_id);
                    setSubStep(1); // StepSimulation
                    sendTag(user, 'cnav::loginameli');
                })
                .catch((error) => {
                        let message
                        if (error.status === 401) {
                            message = "Erreur d'authentification - login et/ou mot de passe incorrect."
                        } else if (error.status !== 401) {
                            message = "Une erreur est survenue, veuillez recommencer ultérieurement."
                        }
                    setFormState({
                        ...formState,
                        errors: {
                            password: [message]
                        },
                        isValid: false
                    });
                })
        }
        variant="transparent"
    >
        <Alert
            title={"Information"}
            message={
            <>
                <Typography>
                    Si vous rencontrez des difficultés de connexion avec Ameli, veuillez utiliser le parcours impot.gouv ou utiliser vos identifiants
                    InfoRetraite.
                </Typography>
                <Typography>
                    Attention : Vous avez 3 essais maximum avant blocage temporaire de votre compte.
                </Typography>
            </>}
            severity={"info"}
        />
        <Inputs.Text
            name="identifier"
            label="Numéro de sécurité sociale (13 chiffres)"
        />
        <Inputs.Text
            name="password"
            label="Mot de passe"
            type="password"
        />
        <Buttons.Default
            submit
            label="Se connecter"
            color="blueLight"
            next
            center
        />
    </Form>
}

const TwoFactorForm = ({sessionId, user, setStepParent, setSubStep}) => {
    const {credentials, publicKey} = useMarel()
    return <>
        <Typography>Veuillez saisir le code de sécurité qui vous a été envoyé</Typography>
        <Form
            schema={{
                otp: {
                    presence: {
                        allowEmpty: false,
                        message: 'Veuillez renseigner votre code de sécurité'
                    }
                },
            }}
            submit={(formState, setFormState) =>
                fastapi
                    .post('/simulators/marel/login/two-factor', {
                        ...formState.values,
                        identifier: encryptCredential(credentials.identifier, publicKey),
                        password: encryptCredential(credentials.password, publicKey),
                        session_id: sessionId
                    })
                    .then((response) => {
                        setSubStep(0);
                        setStepParent(2); // StepSimulation
                        sendTag(user, 'cnav::loginamelitwofactor');
                    })
                    .catch((error) => {
                        let message
                        if (error.response.status === 401) {
                            message = "Erreur d'authentification - login et/ou mot de passe incorrect."
                        } else if (error.response.status !== 401) {
                            message = "Une erreur est survenue, veuillez recommencer ultérieurement."
                        }
                        setFormState({
                            ...formState,
                            errors: {
                                otp: [message]
                            },
                            isValid: false
                        });
                    })
            }
            variant="transparent"
        >
            <Inputs.Text
                name="otp"
                label="Code de sécurité"
            />
            <Buttons.Default
                submit
                label="Valider"
                color="blueLight"
                next
                center
            />
        </Form>
    </>
}

function SubStepLoginAmeli({setStep, setStepParent, setSessionId, sessionId}) {
    const classes = useStyles();
    const {user} = useUser();
    const {setCredentials, publicKey} = useMarel();
    const [subStep, setSubStep] = useState(0);
    return (
        <div className={classes.root}>
            <Typography variant="h4">Connexion simplifiée avec Ameli</Typography>
            {subStep === 0 &&
                <LoginForm
                    user={user}
                    setSubStep={setSubStep}
                    setCredentials={setCredentials}
                    publicKey={publicKey}
                    setSessionId={setSessionId}
                />
            }
            {subStep === 1 &&
                <TwoFactorForm
                    user={user}
                    setStep={setStep}
                    sessionId={sessionId}
                    setSubStep={setSubStep}
                    setStepParent={setStepParent}/>
            }
            <Buttons.Chevron
                label="Retour"
                onClick={() => {
                    setSubStep(0);
                    setStep(1);
                }}
                center
            />
        </div>
    );
}


export default SubStepLoginAmeli;
