import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Buttons, Form, Grid, Info, Inputs, Typography} from 'components';
import {useForm, useProjection, useTaxSimulator} from 'hooks';
import {amount} from 'utils';
import {FormConsumer} from 'store/Form';

import {makeStyles, useTheme} from '@material-ui/styles';
import {Box, useMediaQuery} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    amount: {
        padding: theme.spacing(2, 0, 1)
    },
    button: {
        borderRadius: '0 !important',
        maxWidth: '230px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%'
        }
    }
}));

const InputsProposal = ({adornment, setAdornment}) => {
    const {formState, setFormState} = useForm();
    useEffect(() => {
        setAdornment(formState.values?.allocated_periodicity);
        if (formState.values.allocated_periodicity && formState.touched.allocated_periodicity) {
            let allocated_amount = '0';
            if (['one_shot'].includes(formState.values.allocated_periodicity)) {
                allocated_amount = amount.parse(formState.values?.allocated_amount) * 12;
            } else if (['monthly'].includes(formState.values.allocated_periodicity))
                allocated_amount = amount.parse(formState.values?.allocated_amount) / 12;
            setTimeout(
                () =>
                    setFormState({
                        ...formState,
                        values: {
                            ...formState.values,
                            allocated_amount: allocated_amount.toString()
                        },
                        touched: {
                            ...formState.touched,
                            allocated_amount: true
                        }
                    }),
                0
            );
        }
        // eslint-disable-next-line
    }, [formState.values?.allocated_periodicity]);

    useEffect(() => {
        setTimeout(
            () =>
                setFormState({
                    ...formState,
                    values: {
                        ...formState.values,
                        allocated_amount: parseInt(formState.values.allocated_amount || '0').toString()
                    }
                }),
            0
        );
        // eslint-disable-next-line
    }, [formState.values?.allocated_amount]);
    return (
        <div>
            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    xs={5}
                >
                    <Inputs.Text
                        disableCleave
                        name="allocated_amount"
                        adornment={adornment}
                        right
                        type={'number'}
                    />
                </Grid>
                <Grid
                    item
                    xs={7}
                >
                    <Inputs.Select
                        name="allocated_periodicity"
                        options={[
                            {
                                label: 'Fréquence mensuelle',
                                value: 'monthly'
                            },
                            {
                                label: 'Versement unique',
                                value: 'one_shot'
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

function TabProposal({adornment, setAdornment}) {
    const classes = useStyles();
    const theme = useTheme();

    const {taxSimulator, setTaxSimulator, variant} = useTaxSimulator();
    const {projection, setProjection} = useProjection('retirement');
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box px={isDesktop ? 3 : 1}>
            <Typography
                size={isDesktop ? 24 : 20}
                color="blue"
                center
                variant="h3"
            >
                Ajuster vos versements&nbsp;
            </Typography>

            {taxSimulator?.inputs?.allocated_amount && (
                <Form
                    values={{
                        allocated_periodicity: 'one_shot',
                        allocated_amount: taxSimulator?.inputs?.allocated_amount
                    }}
                    schema={{
                        allocated_periodicity: {presence: {allowEmpty: true}}
                    }}
                    key={'proposal'}
                    name={'proposal'}
                    submit={(formState) => {
                        setTaxSimulator({
                            ...taxSimulator,
                            inputs: {
                                ...taxSimulator.inputs,
                                ...formState.values
                            }
                        });
                        variant === 'contained' &&
                            setProjection({
                                ...projection,
                                inputs: {
                                    ...projection.inputs,
                                    allocated_amount: formState.values.allocated_amount,
                                    allocated_periodicity: formState.values.allocated_periodicity
                                }
                            });
                        return new Promise((resolve) => resolve());
                    }}
                >
                    <InputsProposal
                        adornment={adornment}
                        setAdornment={setAdornment}
                    />
                    <FormConsumer>
                        {(state) =>
                            amount.parse(state.formState.values?.allocated_amount) >=
                                (state.formState.values?.allocated_periodicity === 'one_shot'
                                    ? taxSimulator.response.vv_envelope_monthly * 12
                                    : taxSimulator.response.vv_envelope_monthly) && (
                                <Typography
                                    size={16}
                                    color={'scorpion'}
                                    center={!isDesktop}
                                >
                                    Avec ce montant vous atteignez votre plafond maximum d'économie d'impôt
                                </Typography>
                            )
                        }
                    </FormConsumer>
                    {variant !== 'contained' && (
                        <Buttons.Default
                            className={classes.button}
                            label="Mettre à jour le calcul"
                            color="transparent_border_blue"
                            center
                            iconName="axa-icons-refresh"
                            submit
                        />
                    )}
                </Form>
            )}
            {variant === 'contained' && (
                <Buttons.Chevron
                    label="Je verse"
                    color="blue"
                    center
                    onClick={() => window.open('/transactional/payment', '_blank').focus()}
                />
            )}
        </Box>
    );
}

TabProposal.propTypes = {
    adornment: PropTypes.string.isRequired,
    setAdornment: PropTypes.func.isRequired
};

export default withRouter(TabProposal);
