import {default as getRefundUrl} from './getRefundUrl';
import {default as getTransferUrl} from './getTransferUrl';
import {default as getHomeUrl} from './getHomeUrl';
import {default as getPerformanceDocumentUrl} from './getPerformanceDocumentUrl';

export default {
    getRefundUrl,
    getTransferUrl,
    getHomeUrl,
    getPerformanceDocumentUrl
};
