import React, {useEffect, useMemo} from 'react';
import {Inputs, Typography} from 'components';
import {useForm, useStepper} from 'hooks';
import {amount, wording} from 'utils';

import {makeStyles} from '@material-ui/styles';
import {useParams} from 'react-router-dom';
import {AdvisesContract} from '../../MrmSteps';

const useStyles = makeStyles((theme) => ({
    spacing: {
        '& > label': {
            padding: '5px'
        }
    }
}));

const RadioAnnuityType = () => {
    const classes = useStyles();

    const {formState, setFormState} = useForm();
    const {stepper} = useStepper();

    const reversible = stepper.payload.reversible;
    const options = useMemo(
        () =>
            reversible
                ? [
                      {
                          label: wording.translateAnnuity('RV_R'),
                          value: 'RV_R'
                      },
                      {
                          label: wording.translateAnnuity('RV_RAG'),
                          value: 'RV_RAG'
                      }
                  ]
                : [
                      {
                          label: wording.translateAnnuity('RV_NR'),
                          value: 'RV_NR'
                      },
                      {
                          label: wording.translateAnnuity('RV_NRAG'),
                          value: 'RV_NRAG'
                      },
                      {
                          label: wording.translateAnnuity('RV_CPP'),
                          value: 'RV_CPP'
                      }
                  ],
        [reversible]
    );

    useEffect(() => {
        // Reset `annuity_type` when `reversible` change
        setFormState({
            ...formState,
            values: {
                ...formState.values,
                annuity_type: undefined
            }
        });

        // eslint-disable-next-line
    }, [reversible]);

    return (
        <Inputs.Radio
            className={classes.spacing}
            name="annuity_type"
            labelColor="blue"
            size={18}
            options={options}
        />
    );
};

function StepAnnuityType() {
    const {stepper} = useStepper();
    const {withdraw = 'withdraw'} = useParams();

    const total_annuity = useMemo(
        () =>
            stepper.payload.distribution
                ? Object.entries(stepper.payload.distribution)
                      .filter(([key]) => key.startsWith('annuity'))
                      .map(([, value]) => amount.parse(value))
                      .reduce((acc, val) => acc + val, 0)
                : 0,
        [stepper.payload.distribution]
    );

    return (
        <>
            <Typography
                variant="h3"
                color="blue"
                semibold
                center
            >
                Choisissez une seule option de rente applicable à votre épargne
            </Typography>
            <Typography
                variant="h4"
                color="blue"
                center
            >
                Pour rappel, vous souhaitez bénéficier de&nbsp;
                <Typography
                    component="span"
                    semibold
                >
                    {amount.format(total_annuity)}
                </Typography>
                &nbsp;à convertir en rente
            </Typography>

            <RadioAnnuityType />

            {withdraw === 'withdraw' && <AdvisesContract />}
        </>
    );
}

export default StepAnnuityType;
