import axios from 'axios';
import Cookies from 'js-cookie';
import config from 'config';
import {modal} from 'utils';

export const FASTAPI_URI = `${config.API_URIS[process.env.REACT_APP_ENVIRONMENT]}/v3/`;

axios.defaults.withCredentials = true;

const csrf_token = () => Cookies.get('csrf_token');

const fastapi = axios.create({
    withCredentials: true,
    baseURL: FASTAPI_URI,
    headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token()
    }
});

fastapi.interceptors.response.use(
    (response) => {
        console.log("✅", `${response.config.method.toUpperCase()} ${response.config.url}`);

        return response;
    },
    (error) => {
        console.log("❌", `${error.config.method.toUpperCase()} ${error.config.url}`, error.response || error.config);

        if (error.response?.data?.errorData === 'ERR_CSRF') {
            return fastapi.request({
                ...error.config,
                headers: {
                    ...error.config.headers,
                    'X-CSRF-Token': csrf_token()
                }
            });
        }

        if (error.response?.data?.errorData === 'ERR_TOKEN') {
            modal.open('auto_logout');
        }

        if (error.response?.data?.errorData === 'ERR_MIRROR') modal.open('forbidden_mirror');

        if (error.response?.data?.errorData === 'ERR_CONSENT') window.location.href = '/error-page?reason=ERR_CONSENT';

        if (error.response?.data?.errorData === 'ERR_EXP') {
            modal.open('auto_logout');
        }

        return Promise.reject(error);
    }
);

export default fastapi;
