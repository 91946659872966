import React, {useEffect, useState} from 'react';
import {Redirect, Route as RouterRoute} from 'react-router-dom';
import PropTypes from 'prop-types';
import {AutoLogoutModal} from 'macros';
import {useIsMocked, useUser} from 'hooks';

import {useTheme} from '@material-ui/styles';

const SESSION_DURATION = 21 * 60;

function isSessionExpired(user) {
    const expiredSessionDate = user?.oauth_profile?.auth_time + SESSION_DURATION;
    return Date.now() - expiredSessionDate * 1000 >= 10;
}

function Route(props) {
    const {
        layout: Layout,
        component: Component,
        authenticated,
        breadcrumb,
        background,
        disabled,
        disableHeader,
        disableFooter,
        ...rest
    } = props;
    const theme = useTheme();

    const {user, setUser} = useUser();
    const {isMocked} = useIsMocked()

    const [autoLogout, setAutoLogout] = useState(false);

    useEffect(() => {
        if (user.origin !== 'collective' && !isMocked) {
            const sessionExpired = isSessionExpired(user)

            setAutoLogout(sessionExpired);
            if (sessionExpired) {
                setUser({
                    ...user,
                    logged: false
                });
            }
        }
        // eslint-disable-next-line
    }, [user.oauth_profile, user.logged, authenticated, Component, user.actions]);

    if (disabled) return <Redirect to="/"/>;

    return (
        <RouterRoute
            {...rest}
            render={(matchProps) =>
                authenticated ? (
                    user.id ? (
                        <Layout
                            breadcrumb={breadcrumb}
                            background={background || theme.palette.background.main}
                            disableHeader={disableHeader}
                            disableFooter={disableFooter}
                        >
                            <Component {...matchProps} />
                            <AutoLogoutModal open={autoLogout && authenticated}/>
                        </Layout>
                    ) : (
                        <Redirect to="/"/>
                    )
                ) : (
                    <Layout
                        breadcrumb={breadcrumb}
                        background={background || theme.palette.background.main}
                        disableHeader={disableHeader}
                        disableFooter={disableFooter}
                    >
                        <Component {...matchProps} />
                    </Layout>
                )
            }
        />
    );
}

Route.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    authenticated: PropTypes.bool,
    breadcrumb: PropTypes.node,
    background: PropTypes.string,
    disableHeader: PropTypes.bool,
    disableFooter: PropTypes.bool,
    disabled: PropTypes.bool
};

export default Route;
