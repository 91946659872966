import React from 'react';
import {Link} from 'react-router-dom';
import {api} from 'api';
import {Icon, Structure, Typography} from 'components';
import {useLoading} from 'hooks';

import {Toolbar} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {sendTag} from 'store/Tags';

const useStyles = makeStyles((theme) => ({
    menu: {
        height: 32,
        background: theme.palette.headerPale
    },
    toolbar: {
        height: 58,
        minHeight: 'initial'
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    logo: {
        width: 42,
        height: 42
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 170,
        height: 32,
        color: theme.palette.white,
        background: theme.palette.blue,
        border: 'none',
        cursor: 'pointer',
        float: 'right',
        outline: 'none'
    },
    icon: {
        position: 'relative',
        top: 1
    },
    label: {
        margin: theme.spacing(0, 1)
    }
}));

const LoginButton = () => {
    const classes = useStyles();

    const {setLoading} = useLoading();

    return (
        <button
            className={classes.button}
            onClick={() => {
                setLoading(true);
                localStorage.clear();

                sendTag({}, `Label::header::connecter::espace_client`, {});

                return api
                    .get('/login/oauth/authorization/collective')
                    .then((response) => {
                        window.location.href = response.data;
                    })
                    .finally(() => setLoading(false));
            }}
        >
            <Icon
                className={classes.icon}
                name="axa-icons-person"
                size={16}
            />
            <Typography
                color={'white'}
                className={classes.label}
                gutterBottom={false}
            >
                Espace Client
            </Typography>
        </button>
    );
};

function Desktop() {
    const classes = useStyles();

    return (
        <>
            <div className={classes.menu}>
                <Structure.Center>
                    <LoginButton />
                </Structure.Center>
            </div>
            <Toolbar
                className={classes.toolbar}
                disableGutters
            >
                <Structure.Center className={classes.wrapper}>
                    <Link to="/">
                        <img
                            className={classes.logo}
                            src={`${process.env.PUBLIC_URL}/logos/axa.svg`}
                            alt="logo"
                        />
                    </Link>
                </Structure.Center>
            </Toolbar>
        </>
    );
}

export default Desktop;
