import React, {useState} from 'react';
import createPersistedState from 'use-persisted-state';

import {fastapi} from 'api';
import {Form, Grid, Inputs, Typography} from 'components';
import {Categories, Situations} from './components';
import {useUser} from 'hooks';

import {Box} from '@material-ui/core';

const INDIVIDUAL_SCOPE = 'https://connect.axa.fr/individualOrProfessional.mainCustomerId';

const useMirrorConsent = createPersistedState('EP_mirror_consent');

function SubStepProfessional({selects}) {
    const {user} = useUser();

    const [situation, setSituation] = useState('ACT');
    const [category, setCategory] = useState('NS');

    const [consent, setConsent] = useMirrorConsent(false);

    return (
        <>
            <Typography variant="h5">Ma situation professionnelle</Typography>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    sm={6}
                    xs={12}
                >
                    <Inputs.Select
                        name="professional_situation"
                        label="Quelle est ma situation professionnelle actuelle&nbsp;?"
                        options={selects.professional_situation}
                        optgroup
                        value={situation}
                        onInput={(event) => setSituation(event.target.value)}
                    />
                    <Situations situation={situation} />
                </Grid>
                <Grid
                    item
                    sm={6}
                    xs={12}
                >
                    <Inputs.Select
                        name="professional_category"
                        label="Quelle est mon activité professionnelle&nbsp;?"
                        options={selects.professional_categories}
                        optgroup
                        value={category}
                        onInput={(event) => setCategory(event.target.value)}
                    />
                    <Categories
                        category={category}
                        situation={situation}
                    />
                </Grid>
            </Grid>

            {/* FIXME : Enable individual mirror feature in production */}
            {process.env.REACT_APP_ENVIRONMENT !== 'production' &&
                user.origin === 'individual' &&
                user.oauth_profile &&
                user.oauth_profile[INDIVIDUAL_SCOPE] && (
                    <Box
                        p={1}
                        mt={2}
                    >
                        <Form
                            dynamic
                            submit={(formState) => {
                                if (
                                    user.id &&
                                    typeof formState.values.mirror_consent === 'boolean' &&
                                    formState.values.mirror_consent === !consent
                                ) {
                                    fastapi.post('/consents/mirror', {
                                        user_id: user.id,
                                        mirror_consent: formState.values.mirror_consent,
                                        main_customer_id: user.oauth_profile[INDIVIDUAL_SCOPE],
                                        email: user.email
                                    }).then(() => setConsent(formState.values.mirror_consent));
                                } else return new Promise((resolve) => resolve());
                            }}
                            values={{
                                mirror_consent: consent
                            }}
                        >
                            <Inputs.Switch
                                name="mirror_consent"
                                label="Dans un objectif d'assistance à l'utilisation ou de conseil à la préparation de mes projets,
                j'accepte de partager mes éléments de simulation avec mes interlocuteurs AXA"
                                defaultValue={consent}
                            />
                        </Form>
                    </Box>
                )}
        </>
    );
}

export default SubStepProfessional;
