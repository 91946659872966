import React from 'react';
import PropTypes from 'prop-types';
import {fastapi} from 'api';
import {Form, Inputs, Modal} from 'components';
import {useB2V, useLoading, useUser} from 'hooks';
import {modal} from 'utils';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 500
    },
    form: {
        padding: theme.spacing(0, 1)
    }
}));

function ConsentB2VModal({hash}) {
    const {user} = useUser();

    const {B2V, setB2V} = useB2V();

    const {setLoading} = useLoading();

    const classes = useStyles();

    if (!B2V) return null;

    return (
        <Modal
            hash={hash}
            className={classes.root}
            title="Consentement"
        >
            <Form
                className={classes.form}
                dynamic
                values={{
                    b2v_consent: B2V.consent
                }}
                submit={(formState) => {
                    if (formState.values.b2v_consent !== B2V.consent && user.id) {
                        setLoading(true);

                        user.id &&
                            fastapi
                                .post('/consents/b2v', {
                                    user_id: user.id,
                                    b2v_consent: formState.values.b2v_consent
                                })
                                .then(() => {
                                    setTimeout(() => {
                                        modal.close(hash);
                                    }, 1000);

                                    setTimeout(() => {
                                        setLoading(false);
                                        setB2V({
                                            ...B2V,
                                            consent: formState.values.b2v_consent
                                        });
                                    }, 1100);
                                });
                    } else return new Promise((resolve) => resolve());
                }}
            >
                <Inputs.Switch
                    name="b2v_consent"
                    label="Souhaitez-vous que les données issues de votre contrat retraite assuré par le BCAC (Fonds de
                pension professionnel) soient transmises à AXA France afin que nous les agrégions aux autres données et
                ce, pour vous donner une vision consolidée de l’ensemble de votre épargne retraite d’entreprise ?"
                />
            </Form>
        </Modal>
    );
}

ConsentB2VModal.propTypes = {
    hash: PropTypes.string.isRequired
};

export default ConsentB2VModal;
