import {useContext, useEffect} from 'react';

import {fastapi} from 'api';
import {sendTag} from 'store/Tags';
import {TaxSimulatorContext} from 'store/TaxSimulator';
import {amount, json, objectEquals} from 'utils';
import {useUser} from "./index";

let memoized_inputs = '';

const isFilled = (input) => {
    return !!input;
};

const getTaxSimulation = (inputs, callback, user) => {
    if (
        !['gender', 'client_age', 'marital_status', 'monthly_income'].every(
            (val) => Object.keys(json.parse(inputs)).indexOf(val) !== -1
        )
    )
        return;

    if (inputs === memoized_inputs) return;
    memoized_inputs = inputs;

    fastapi
        .post('/simulators/tax/simulation', json.parse(inputs))
        .then((response) => callback(response))
        .then(() => sendTag(user,'tax::simulationcomplete'));
};

function useTaxSimulator() {
    const context = useContext(TaxSimulatorContext);
    const {user} = useUser();
    const inputs = JSON.stringify(context.taxSimulator.inputs);

    useEffect(() => {
        getTaxSimulation(inputs, (response) => {
            if (!objectEquals(response.data, context.taxSimulator.response)) {
                context.setTaxSimulator({
                    inputs: {
                        ...context.taxSimulator.inputs,
                        allocated_amount: isFilled(context.taxSimulator.inputs.allocated_amount)
                            ? context.taxSimulator.inputs.allocated_amount
                            : amount.format(response.data.vv_envelope_monthly * 12 || '0', 0, '', ',', '')
                    },
                    response: response.data
                });
            }
        }, user);

        // eslint-disable-next-line
    }, [inputs]);

    return context;
}

export default useTaxSimulator;
