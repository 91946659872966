import React from 'react';
import clsx from 'clsx';
import {Info, Typography} from 'components';
import {useTaxSimulator} from 'hooks';
import {amount} from 'utils';

import {makeStyles, useTheme} from '@material-ui/styles';
import {useMediaQuery} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding: theme.spacing(0, 3)
    },
    disabled: {
        background: '#c2c2c2'
    },
    border: {
        height: '100%',
        minHeight: 150,
        border: `solid 2px ${theme.palette.grayLight}`,
        padding: theme.spacing(2, 1)
    },
    amount: {
        padding: theme.spacing(2, 0, 1)
    }
}));

function TabEconomy() {
    const classes = useStyles();
    const theme = useTheme();

    const {taxSimulator, variant} = useTaxSimulator();

    const enabled = variant === 'contained' ? !!taxSimulator.response.tax_reduction : true;

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <div
            className={clsx({
                [classes.wrapper]: true,
                [classes.border]: variant === 'contained',
                [classes.disabled]: !enabled
            })}
        >
            <Typography
                size={isDesktop ? 24 : 20}
                color={enabled ? 'blue' : 'black'}
                center
                variant="h3"
            >
                Vous économiserez&nbsp;
                {enabled && (
                    <Info
                        hash="disclaimer_tax_2"
                        title="Information"
                        color="blue"
                    >
                        L'estimation ne prend pas en compte votre enveloppe fiscale non utilisée ni celle de votre
                        conjoint. La simulation ne tient pas compte des éventuels versements et cotisations sur vos
                        contrats de retraite supplémentaire Article 83, contrats PERP, PREFON, PER et abondement perçu
                        au titre d'un PERCO ou d'un PER. Ces éléments figurent dans votre feuille d'impôts.
                    </Info>
                )}
            </Typography>
            <Typography
                className={classes.amount}
                size={isDesktop ? 32 : 24}
                color={enabled ? 'blue' : 'black'}
                semibold
                center
            >
                {amount.format(taxSimulator.response.tax_reduction, 0, "€ d'impôts")}
            </Typography>
            {enabled ? (
                <Typography
                    size={isDesktop ? 18 : 16}
                    color="blue"
                    center
                    capitalizeSentense={false}
                >
                    en versant&nbsp;
                    {amount.format(
                        taxSimulator.inputs.allocated_periodicity === 'monthly'
                            ? 12 * amount.parse(taxSimulator.inputs.allocated_amount)
                            : amount.parse(taxSimulator.inputs.allocated_amount),
                        0,
                        '€'
                    )}
                    &nbsp;en {new Date().getFullYear()} uniquement pour l'Article 83 et le PER
                </Typography>
            ) : (
                <Typography
                    size={isDesktop ? 18 : 16}
                    center
                >
                    en {new Date().getFullYear()}
                </Typography>
            )}
        </div>
    );
}

export default TabEconomy;
