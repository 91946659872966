import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/styles';
import {Trans} from 'react-i18next';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
    button: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content'
    },
    blue: {
        position: 'relative',
        minWidth: '14rem',
        transition:
            'color .5s cubic-bezier(0, 0.99, 0.865, 1.005), background-color .6s cubic-bezier(0, 0.99, 0.865, 1.005)',
        textAlign: 'center',
        display: 'block',
        margin: theme.spacing(1, 0),
        padding: 12,
        border: '1px solid transparent',
        userSelect: 'none',
        color: theme.palette.white,
        backgroundColor: theme.palette.blue,
        '&:hover, &:focus-visible': {
            backgroundColor: theme.palette.blueDark
        }
    },
    scorpion: {
        color: theme.palette.scorpion,
        '&:hover, &:focus-visible': {
            outline: 'none',
            textDecoration: 'underline'
        }
    },
    centerText: {
        textAlign: 'center'
    },
    center: {
        margin: `${theme.spacing(1)}px auto`
    },
    label: {
        fontSize: 18,
        letterSpacing: '0.22px',
        fontWeight: '600',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('md')]: {
            fontSize: 16
        }
    },
    back: {
        color: theme.palette.scorpion,
        border: 'solid 2px #5F5F5F',
        borderRadius: '50%',
        marginRight: theme.spacing(1)
    }
}));

function Link(props) {
    const {href, label, className, classLabel, color, center, centerText, title, ariaLabel, back, ...rest} = props;

    const classes = useStyles();

    return (
        <a
            href={href}
            className={clsx({
                [classes.button]: true,
                [classes[color]]: !!color,
                [classes.center]: !!center,
                [className]: className
            })}
            {...rest}
        >
            {back && (
                <ArrowBackIcon
                    fontSize={'medium'}
                    className={classes.back}
                />
            )}
            <span
                className={clsx({
                    [classes.centerText]: centerText
                })}
            >
                <span className={clsx(classes.label, classLabel && classLabel)}>
                    <Trans i18nKey={label} />
                </span>
            </span>
        </a>
    );
}

Link.propTypes = {
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    classLabel: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.node,
    iconName: PropTypes.string,
    center: PropTypes.bool,
    right: PropTypes.bool,
    centerText: PropTypes.bool,
    previous: PropTypes.bool,
    next: PropTypes.bool
};

export default Link;
