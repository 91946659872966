import React, {useEffect} from 'react';
import './i18n/i18n';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {ForbiddenMirrorModal, SessionBuilder} from 'macros';

import Providers from './Providers';
import TagsProvider, {TagsHelmet} from 'store/Tags';

import './assets/scss/index.scss';
import Routes from './Routes';

import '__mocks__';

const browserHistory = createBrowserHistory();

const App = () => {
    const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

    useEffect(() => {
        if (!window.location.pathname.includes('developer')) {
            // Remove hash on refresh
            window.location.replace('#');

            if (typeof window.history.replaceState === 'function') {
                window.history.replaceState({}, '', window.location.href.slice(0, -1));
            }
        }
    });

    return (
        <Providers>
            <TagsHelmet />
            <Router history={browserHistory}>
                {!['production'].includes(ENVIRONMENT) && <SessionBuilder />}
                    <TagsProvider>
                        <Routes />
                    </TagsProvider>
                <ForbiddenMirrorModal />
            </Router>
        </Providers>
    );
};

export default App;
